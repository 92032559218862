import React, { useState } from "react"
import { navigate } from "gatsby"
// import { Builder, BuilderComponent } from '@builder.io/react';

import Layout from '../components/organisms/Layout/Layout';
import Seo from '../components/organisms/Seo/Seo';
import Container from '../components/atoms/Container/Container';
import FormInputField from "../components/atoms/FormInputField/FormInputField";
import Button from "../components/atoms/Button/Button";

import * as styles from './404.module.css';

const PageNotFound = () => {
  // if (Builder.isPreviewing || Builder.isEditing) {
  //   return <BuilderComponent model="page" />;
  // }

  const [search, setSearch] = useState('');
  
  const handleSubmit = (e) => {
    e.preventDefault();
    navigate(`/search?q=${search}`);
  }

  return (
    <Layout disablePaddingBottom>
      <Seo title="Page Not Found" />
      <Container size={'medium'}>
        <div className={styles.root}>
          <h1>404 Error</h1>
          <h2>Page not found</h2>
          <p>Uh oh, looks like the page you are looking for has moved or no longer exists.</p>
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className={styles.searchContainer}>
              <FormInputField 
                id={'name'}
                value={search}
                handleChange={(_, e) => setSearch(e)}
                type={'text'}
              />
              <Button type={'submit'} level={'primary'}>search</Button>
            </div>
          </form>
        </div>
      </Container>
    </Layout>
  );
};

export default PageNotFound;
