import React from 'react'; // , { useState }
import { Link, navigate } from 'gatsby';
import { get } from 'lodash';
import Button from '../Button/Button';
// import Dialog from '../Dialog/Dialog';

import * as styles from './MyUsu.module.css';

const MyUsuClub = ({
  club,
  // onLeave,
  usuNumber,
  eventClick,
  hasLink = false,
  level,
}) => {
  const isExec = club.acf.isExec;
    // (club.acf.club_execs &&
    //   club.acf.club_execs.findLast(
    //     member =>
    //       member.member_number === usuNumber ||
    //       usuNumber === '2303541' ||
    //       usuNumber === '2351174' // Ash D's USU account for dev access
    //   )) ||
    // false;

  // const [showPrompt, setShowPrompt] = useState(false);

  // const vote = () => {
  //   navigate(`/account/my-usu/clubs/vote`, {
  //     state: {
  //       clubSlug: club.slug,
  //       clubId: club.id,
  //       clubTitle: club.title.rendered,
  //       clubStatus: club.status,
  //       eventId: club.eventId,
  //       voteEnabled: true,
  //       memberNumber: usuNumber,
  //     },
  //   });
  // };

  const nominate = () => {
    navigate(`/account/my-usu/clubs/nominate`, {
      state: {
        clubSlug: club.slug,
        clubId: club.id,
        clubTitle: club.title.rendered,
        clubStatus: club.status,
        clubRoles: club.acf.club_roles,
        eventId: club.eventId,
        nominationsEnabled: true,
        memberNumber: usuNumber,
      },
    });
  };

  const roClick = () => {
    navigate(`/account/my-usu/clubs/manage/run-gm`, {
      state: {
        clubSlug: club.slug,
        clubId: club.id,
        clubTitle: club.title.rendered,
        clubStatus: club.status,
        clubRoles: club.acf.club_roles,
        eventId: club.eventId,
        memberNumber: usuNumber,
      },
    });
  };

  return (
    <div className={styles.card}>
      <div className={styles.content}>
        {hasLink && (
          <Link className={styles.overlay} to={`/clubs/${club.slug}`}></Link>
        )}
        <img src={club.acf.logo} alt='' />
        <div className={styles.eventDetails}>
          <h1 className='title'>
            {club.acf.full_title || club.title.rendered}
          </h1>
          <p
            className='excerpt'
            dangerouslySetInnerHTML={{ __html: club.excerpt.rendered }}></p>
        </div>
      </div>
      <div className={`${styles.actions} ${styles.stack}`}>
        {club.status !== 'publish' && (
          <div className={styles.currentStatus}>
            Current Status:{' '}
            <span>
              {club.status === 'applied' && <>Applied</>}
              {club.status === 'signatures' && <>Fetching interest</>}
              {club.status === 'compliant' && <>Awaiting approval</>}
              {club.status === 'ign' && <>Holding IGM</>}
              {club.status === 'engaged' && <>Under review</>}
            </span>
          </div>
        )}

        {club.status === 'publish' && (
          <div className={styles.currentMemberLevel}>
            Membership Level: <span>{level}</span>
          </div>
        )}

        {isExec && ['applied', 'engaged'].indexOf(club.status) === -1 ? (
          <>
            {isExec.position === 'Returning Officer' ? (
              <Button
                onClick={() =>
                  roClick(club.slug, club.id, club.title.rendered, club.status)
                }
                level='primary'
                size='tinier'
                theme='grey'
                type='span'>
                Manage
              </Button>
            ) : (
              <Button
                onClick={() =>
                  eventClick(
                    club.slug,
                    club.id,
                    club.title.rendered,
                    club.status
                  )
                }
                disabled={['applied','signatures','compliant'].includes(club.status)}
                level='primary'
                size='tinier'
                theme='grey'
                type='span'>
                Manage
              </Button>
            )}
          </>
        ) : (
          <>
            {/* UAT 19 Jan request to remove leave button: https://matterdesign.atlassian.net/wiki/spaces/USU/pages/2311028772/2023-01-19+UAT+Feedback+Meeting+notes */}
            {/*['applied', 'compliant', 'ign', 'engaged'].indexOf(club.status) === -1 && (
            <Button
              level="primary"
              size="tinier"
              theme="grey"
              type='span'
              onClick={() => setShowPrompt(true)}>
              {club.status === 'publish' && (<>Leave</>)}
              {club.status === 'signatures' && (<>Revoke</>)}
            </Button>
          )*/}
          </>
        )}
        {(!isExec || club.acf.execPosition !== 'Returning Officer') &&
          ((club.status === 'ign' &&
            get(club, 'nominationsOpen', false) === true) ||
            (club.status === 'publish' &&
              get(club, 'acf.agm_functionality', false) === true &&
              get(club, 'nominationsOpen', false) === true)) &&
          !get(club, 'acf.hasRO', false) && (
            <Button
              onClick={() => nominate()}
              level='primary'
              size='tinier'
              theme='grey'
              type='span'>
              Nominations
            </Button>
          )}

        {/* {(!isExec || isExec.position !== 'Returning Officer') &&
          ((club.status === 'ign' &&
            get(club, 'memberCheckedIn', false) === true) ||
            (club.status === 'publish' &&
              get(club, 'acf.agm_functionality', false) === true &&
              get(club, 'memberCheckedIn', false) === true)) && (
            <Button
              onClick={() => vote()}
              level='primary'
              size='tinier'
              theme='grey'
              type='span'>
              Vote
            </Button>
        )} */}
      </div>
      {/* <Dialog
        onCancel={() => setShowPrompt(false)}
        open={showPrompt}
        onOk={() => onLeave(club.slug, level)}
        size={'xs'}
        okBtnText={'Leave'}
        hideBtnClose
      >
        <div className={styles.dialogPromptContainer}>
          <p>Are you sure you wish to leave the {club.title.rendered} club?</p>
        </div>
      </Dialog> */}
    </div>
  );
};

export default MyUsuClub;

